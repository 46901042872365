import format from "date-fns/format";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

const useCalendar = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const CLIENT_ID = process.env.VUE_APP_CLIENT_ID_CALENDAR;
  const API_KEY = process.env.VUE_APP_API_KEY_CALENDAR;
  const SCOPES =
    "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  const gapi = window.gapi;
  const emailCalendar = ref("");
  const events = ref([]);
  const isLoadingAccount = ref(false);
  const curr = new Date(store.getters['shared/currentTimeTimezone']);
  const first = curr.getDate() - curr.getDay();
  const last = first + 6;

  const firstday = new Date(curr.setDate(first)).toISOString();
  const lastday = new Date(curr.setDate(last)).toISOString();

  function handleClientLoad() {
    gapi.load("client:auth2", initClient);
  }

  const loadServices = async () => {
    await store.dispatch("students/classesTakenThisWeek");
    await store.dispatch("students/classesThisWeekPending");
  };

  function initClient() {
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
        plugin_name: "Bright Lingua"
      })
      .then(
        function () {
          // Listen for sign-in state changes.
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

          // Handle the initial sign-in state.
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        },
        function (error) {
          // console.log("Error",error)
        }
      );
  }

  async function updateSigninStatus(isSignedIn) {
    const getUserAuthentication = store.getters["shared/getUserAuthentication"];
    let useInformation = store.getters["students/studentInformation"];
    if (isSignedIn && getUserAuthentication.role === "student") {
      const profile = gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getBasicProfile();
      if (
        useInformation.email_google_calendar === "" &&
        useInformation.email_google_calendar === null
      ) {
        // await store.dispatch("students/linkGoogleEmail",{email:profile.getEmail()})
        // store.dispatch("shared/resetNotificationStatus");
        // await store.dispatch("students/getStudentInfo");
      }
      store.commit("shared/setCalendarEmail", {
        userEmail: profile.getEmail(),
        isLoggedIn: true,
      });
      listUpcomingEvents();
    } else {
      if (getUserAuthentication.role === "student") {
        if (
          useInformation.email_google_calendar !== "" &&
          useInformation.email_google_calendar !== null
        ) {
          // await store.dispatch("students/unLinkGoogleEmail")
          // store.dispatch("shared/resetNotificationStatus");
          // await store.dispatch("students/getStudentInfo");
        } else {
          // if (route.fullPath === "/students/student-my-classes") {
          //   const notification = { isOpen: true };
          //   notification.message =
          //     "We should be able to link your Google Calendar account to your account. Please sign in with your Google account in your Profile page.";
          //   notification.status = "warning";
          //   // store.commit("shared/uiNotificationStatus", notification);
          //   // store.dispatch("shared/resetNotificationStatus");
          // }
        }
      }
      store.commit("shared/setCalendarEvents", []);
    }
  }

  function handleAuthClick(event) {
    isLoadingAccount.value = true;
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(async (data) => {
        const profile = gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .getBasicProfile();
        await store.dispatch("students/linkGoogleEmail", {
          email: profile.getEmail(),
        });
        await store.dispatch("students/dashboardStart");
        await store.dispatch("students/getStudentInfo");

        isLoadingAccount.value = false;
      })
      .catch((err) => {
        console.log(err)
        isLoadingAccount.value = false;
        // console.log(err)
      });

  }

  async function handleSignoutClick(event) {
    isLoadingAccount.value = true;
    await store.dispatch("students/unLinkGoogleEmail");
    await store.dispatch("students/getStudentInfo");
    await store.dispatch("students/dashboardStart");
    isLoadingAccount.value = false;
    gapi.auth2.getAuthInstance().signOut();
  }

  async function listUpcomingEvents() {
    const response = await gapi.client.calendar.events.list({
      calendarId: "primary",
      timeMin: firstday,
      timeMax: lastday,
      showDeleted: false,
      singleEvents: true,
      orderBy: "startTime",
    });
    const eventsResponse = await response.result.items;
    // filtrar eventos
    if (eventsResponse.length > 0) {
      eventsResponse.map((event) => {
        events.value.push({
          title: event.summary,
          start: format(new Date(event.start.dateTime), "y-MM-d H:mm:ss"),
          end: format(new Date(event.end.dateTime), "y-MM-d H:mm:ss"),
          content: event.description,
        });
      });
    }
    store.commit("shared/setCalendarEvents", events.value);
  }

  const calendarEvents = computed(
    () => store.getters["shared/getCalendarEvents"]
  );

  function createEvent(event) {
    // if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
    //   if (gapi.client.calendar !== undefined) {
    //     let request = gapi.client.calendar.events.insert({
    //       calendarId: "primary",
    //       resource: event,
    //     });
    //     request.execute(function (e) {
    //       const notification = { isOpen: true };
    //       if (e.code === 400) {
    //         notification.message =
    //           "Ups something went wrong creating your class in google calendar. Please try again later.";
    //         notification.status = "warning";
    //         store.commit("shared/uiNotificationStatus", notification);
    //         store.dispatch("shared/resetNotificationStatus");
    //       } else {
    //         notification.message =
    //           "Your class has been created in google calendar.";
    //         notification.status = "success";
    //         store.commit("shared/uiNotificationStatus", notification);
    //         store.dispatch("shared/resetNotificationStatus");
    //       }
    //     });
    //   }
    // } else {
    //   const notification = { isOpen: true };
    //   notification.message =
    //     "We should be able to link your Google Calendar account to your Bright Lingua account. Please sign in with your Google account in your Profile page.";
    //   notification.status = "warning";
    //   store.dispatch("shared/resetNotificationStatus");
    //   store.dispatch("students/unLinkGoogleEmail");
    //   setTimeout(() => {
    //     store.commit("shared/uiNotificationStatus", notification);
    //   }, 3000);
    // }
  }

  const classesWeekStudent = computed(() => {
    return store.getters["students/classesTakenThisWeek"];
  });

  const classesThisWeekPendingStudent = computed(() => {
    return store.getters["students/classesThisWeekPending"];
  });

  return {
    handleClientLoad,
    classesThisWeekPendingStudent,
    classesWeekStudent,
    emailCalendar,
    handleAuthClick,
    isLoadingAccount,
    handleSignoutClick,
    calendarEvents,
    createEvent,
    loadServices,
  };
};

export default useCalendar;
