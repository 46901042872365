import { differenceInCalendarISOWeeks } from "date-fns";
import { ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";
const useCalendarTeacher = () => {
  const store = useStore();
  const events = ref([]);

  const modalClass = (event) => {
    const classModal = store.getters["teachers/classModal"];
    if (!classModal.isOpen)
      store.commit("teachers/classModal", {
        isOpen: !classModal.isOpen,
        data: event,
      });
    else store.commit("teachers/classModal", { isOpen: !classModal.isOpen });
  };
  const loadServices = async () => {
    await store.dispatch("teachers/classesTakenThisWeek");
  };
  const classesWeek = computed(() => {
    return store.getters["teachers/teacherClassesTaughtThisWeek"];
  });

  const setEvents = (classesWeek) => {
    events.value = [];
    classesWeek.forEach((classe) => {
      events.value.push({
        start: classe.lesson_start,
        end: classe.lesson_end,
        title: "My class",
        content: `<p>Class with ${classe.student_name} ${classe.student_last_name}</p>`,
        class: "leisure",
        type: "class",
        comment_goal: classe.comment_goal,
        comment_description: classe.comment_description,
        level_name: classe.level_name,
        student_name: classe.student_name,
        student_last_name: classe.student_last_name,
        startText: classe.lesson_start,
        endText: classe.lesson_end,
        studentId:classe.student_id,
        linkZoom:classe.link_zoom
      });
    });
  };

  watchEffect(() => {
    let classesWeek = store.getters["teachers/teacherClassesTaughtThisWeek"];
    if (classesWeek.data.length > 0) {
      setEvents(classesWeek.data);
    }
  });

  const classModal = computed(() => {
    return store.getters["teachers/classModal"];
  });

  return {
    classModal,
    loadServices,
    events,
    classesWeek,
    modalClass,
  };
};

export default useCalendarTeacher;
