<template>
  <div :class="admin ? 'admin-cal' : null" class="custom-calendar">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
      <h3 class="text-primary-1 mb-4 d-none d-md-block">{{ title }}</h3>
      <h3 class="text-primary-1 mb-4 d-block d-md-none">{{ titleMobile }}</h3>
      <div class="d-none d-md-flex gap-2 align-items-center mb-4 fw-bold fs-6 text-primary-1">
        <i class="fw-bold fs-6 pi pi-calendar"></i>
        <p>Week: {{ month_from_date }} {{ first_day }} - {{ month_to_date }} {{ last_day }}</p>
      </div>
    </div>

    <div class="d-none d-xl-block">
      <VueCal class="desktop" ref="vuecal" :time-from="startDate" :time-step="30" active-view="week" hide-view-selector
        :disable-views="['day']"  :events="events || calendarEvents" startWeekOnSunday
        :onEventClick="handleEventClick"
        @cell-click="changeViewDesk"
        @click="changeViewDesk"
        >
        <template v-slot:weekday-heading="date">
          <span class="custom-weekday-label">
            <!-- <small v-if="date.heading.today">Today</small> -->
            <small class="date_month">{{ format(date.heading.date, "LLLL") }}</small>
            {{ format(date.heading.date, "eee&nbsp;d") }}
          </span>
        </template>
      </VueCal>
    </div>
    <div class="d-block d-xl-none">
      <VueCal class="mobile" ref="vuecal" :time-from="startDateMobile" :time-step="30" active-view="day" hide-view-selector
        :disable-views="['month','year']" small :events="events || calendarEvents" startWeekOnSunday
        :onEventClick="handleEventClick"
        @view-change="changeView"
        @cell-click="changeViewDesk"
        @ready="changeView"
        
        >
        <template v-slot:weekday-heading="date">
          <span class="custom-weekday-label">
            <small v-if="date.heading.today">Today</small>
            <small v-else>{{ format(date.heading.date, "LLLL") }}</small>
            {{ format(date.heading.date, "eee d") }}
          </span>
        </template>
      </VueCal>
    </div>

  </div>
</template>

<script setup>
import format from "date-fns/format";
import { computed, onMounted, onUpdated, ref, watchEffect, watch } from "vue";
import moment from "moment";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "./calendar.scss";
import useCalendar from "@/shared/composables/Calendar/useCalendar";
import useCalendarTeacher from "@/modules/teachers/composables/MyCalendar/useCalendarTeacher";
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
  title: String,
  admin: Boolean,
  events: Object,
  titleMobile: String,
});

const from_date = moment(store.getters['shared/currentTimeTimezone']).startOf('week');
const month_from_date = from_date.format('MMM');
const to_date = moment(store.getters['shared/currentTimeTimezone']).endOf('week');
const month_to_date = to_date.format('MMM')
const first_day = from_date.format('DD');
const last_day = to_date.format('DD');

const emit = defineEmits(["onEventClick"]);

const startDate = ref(0);
const startDateMobile = ref(0);
const vuecal = ref(null);
const eventClicked = ref(null);
const { modalShow } = useCalendarTeacher();
const { calendarEvents } = useCalendar();
const today = ref(null);


watchEffect(() => {
  let eventos = props.events || calendarEvents.value;
  if (eventos.length) {
    for (let i = 0; i < eventos.length; i++) {
      for (let x = 0; x < eventos.length; x++) {
        if (
          new Date(eventos[i].start).getHours() <
          new Date(eventos[x].start).getHours()
        ) {
          let aux = eventos[i];
          eventos[i] = eventos[x];
          eventos[x] = aux;
        }
      }
    }
    startDate.value = new Date(eventos[0].start.replace(/-/g, "/"))
    .getHours() * 60;
  }
  today.value = moment(store.getters['shared/currentTimeTimezone'])
});

onUpdated(() => {
  updateToday()
})

const updateToday = () => {
  const headings = document.querySelectorAll('.vuecal__heading')
  const columns = document.querySelectorAll('.vuecal__cell')
  setTimeout(() => {
    headings.forEach(head => {
      head.classList.remove('today')
      const text = head.querySelector('.custom-weekday-label').innerText
      if(today.value && today.value.format('DD') == 
      text.slice(text.length - 2, text.length)){
        head.classList.add('today')
        head.querySelector('.date_month').innerText = 'Today'
      }
    })
    columns.forEach((col, index) => {
      col.classList.remove('vuecal__cell--today')
      if(vuecal.value.weekDays[index]?.label.toLowerCase() === 
      moment(today.value).format('dddd').toLowerCase()){
        col.classList.add('vuecal__cell--today')
      }
    })
  }, 300)
}

const changeViewDesk = () => {
  updateToday()
}

const changeView = (e) => {
  updateToday()
   if(e.events.length){
    startDateMobile.value = new Date(e.events[0].start)
    startDateMobile.value = e.events[0].start
     .getHours() * 60
     return 
   }
   startDateMobile.value = 0
}

const handleEventClick = (event) => {
  if (props.admin) {
    emit("onEventClick", event);
    return;
  }
  if (eventClicked.value === event) {
    emit("onEventClick", event);
    return;
  } else {
    emit("onEventClick", event);
  }
  eventClicked.value = event;
};
</script>


<style lang="scss">
.angle {
  color: #264e9c !important;
}

.vuecal__title-bar {
  background-color: #dee7f7 !important;
  margin-bottom: 0.5rem;
  border-radius: 15px;

  .vuecal__title button {
    color: #264e9c !important;
  }
}
</style>